// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-component-blog-post-js": () => import("/opt/build/repo/src/component/blog-post.js" /* webpackChunkName: "component---src-component-blog-post-js" */),
  "component---src-pages-en-about-me-js": () => import("/opt/build/repo/src/pages/en/about-me.js" /* webpackChunkName: "component---src-pages-en-about-me-js" */),
  "component---src-pages-en-en-index-js": () => import("/opt/build/repo/src/pages/en/en-index.js" /* webpackChunkName: "component---src-pages-en-en-index-js" */),
  "component---src-pages-en-games-js": () => import("/opt/build/repo/src/pages/en/games.js" /* webpackChunkName: "component---src-pages-en-games-js" */),
  "component---src-pages-en-passion-projects-js": () => import("/opt/build/repo/src/pages/en/passion-projects.js" /* webpackChunkName: "component---src-pages-en-passion-projects-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ru-about-me-js": () => import("/opt/build/repo/src/pages/ru/about-me.js" /* webpackChunkName: "component---src-pages-ru-about-me-js" */),
  "component---src-pages-ru-games-js": () => import("/opt/build/repo/src/pages/ru/games.js" /* webpackChunkName: "component---src-pages-ru-games-js" */),
  "component---src-pages-ru-passion-projects-js": () => import("/opt/build/repo/src/pages/ru/passion-projects.js" /* webpackChunkName: "component---src-pages-ru-passion-projects-js" */),
  "component---src-pages-ru-ru-index-js": () => import("/opt/build/repo/src/pages/ru/ru-index.js" /* webpackChunkName: "component---src-pages-ru-ru-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

