/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import 'bootstrap'

const onInitialClientRender = () => {
    console.log('Close it right now or i am gonna call the police');
}
export {
    onInitialClientRender
}
